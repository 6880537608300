export const SERVICES = {
    LOGIN: '/auth/login',
    RECOVERY: '/auth/reset-password-session',
    REQUEST_RESET_PASSWORD: '/auth/request-reset-password',
    RESET_PASSWORD: '/auth/reset-password',
    MENU: '/auth/menu',
    SUSCRIPTION: '/suscription',
    USER: '/user',
    USER_DELETE: '/user/delete',
    CLIENT: '/client',
    CLIENT_LIST: '/client/list',
    USER_LIST: '/user/list',
    USER_LIST_GROUP: '/user/list-group',
    USER_LIST_ADMIN: '/user/list/admin',
    RONDAS: '/rondas',
    TRACKING: '/tracking',
    MONITORING: '/monitoring',
    REPORT: '/report',
    REPORT_PDF: '/report/get-file',
    RONDAS_LIST: '/rondas/list'
}