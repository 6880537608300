// auth.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface IMenu {
  "module": string,
  "url": string,
  "icon": string,
  "disabled": boolean
}

export interface INotification {
  message?: string,
  state: boolean,
  type?: 'error' | 'success'
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private menuSubject: BehaviorSubject<Array<IMenu>>;
  menuIn$: Observable<Array<IMenu>>;
  private viewSuscription: string = '';

  private notificationSubject: BehaviorSubject<INotification>;
  notificationIn$: Observable<INotification>;

  constructor() {
    this.menuSubject = new BehaviorSubject<Array<IMenu>>([]);
    this.menuIn$ = this.menuSubject.asObservable();

    this.notificationSubject = new BehaviorSubject<INotification>({ state: false });
    this.notificationIn$ = this.notificationSubject.asObservable();
  }


  setMenu(menu: Array<IMenu>): void {
    this.menuSubject.next(menu);
  }

  setNotification(data: INotification): void {
    this.notificationSubject.next(data);
  }

  setViewSuscription(suscription: string): void {
    this.viewSuscription = suscription;

    if(suscription) {
      sessionStorage.setItem('suscription', suscription);
    }
  }

  cleanViewSuscription(): void {
    this.viewSuscription = "";
    sessionStorage.removeItem('suscription');
  }

  getViewSuscription(): string|null {
    if(this.viewSuscription){
      return this.viewSuscription;
    }

    return sessionStorage.getItem('suscription')
  }
}
